<template>
  <div class="configPage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "configPage"
};
</script>